@import "../index";

.container__room__map_screen {
  display: flex;
  flex-direction: column;
  @extend .container__room;
}

.container__room_item__map_screen {
  @extend .card_list_item__reserve;
  @extend .room_card__small;
  border: .5px solid transparent;
  &:hover {
    cursor: default;
  }
}

.container__room_item__map_screen__active {
  @extend .container__room_item__map_screen;
  border: .5px solid $blue;
  &:hover {
    cursor: default;
  }
}

.container__rooms_and_map {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100%;
  min-width: 1px;
  overflow: visible;
  margin-top: 10px;
  & > .flex_container {
    margin-bottom: 10px;
  }
}

.container_map {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  flex: 3;
  max-width: 100%;
  min-width: 1px;
  width: 100%;
  & > div > div {
    border-radius: 8px;
  }
}

.container_map__inner {
  width: fit-content;
  height: fit-content;
  position: relative;
  border-radius: 8px;
}

.container__add_image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.container__room_type {
  @extend .flex_container__column;
  user-select: none;
  & > div:nth-child(2) {
    padding: 0 2px;
  }
  & .container__title__rooms_group_type {
    border: .5px solid transparent;
    transition: border-color .2s ease;
  }
  &:first-child .container__title__rooms_group_type {
    margin-top: 0;
  }
}

.container__room_type__active {
  @extend .container__room_type;
  & .container__title__rooms_group_type {
    border: .5px solid $blue;
  }
  margin-bottom: 14px;
}

.container__floor__select {
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: $topCardBG;
  margin-right: 5px;
  min-width: 35px;
  border-radius: 5px;
  border: .5px solid transparent;
  cursor: pointer;
  height: 40px;
  width: 30%;
  max-width: 400px;
  padding: 4px 10px 4px 20px;
  position: relative;
  transition: border-color .2s ease;
  user-select: none;
}

.container__floor__select__active {
  @extend .container__floor__select;
  border: .5px solid $blue;
}

.pop_up__floor__select {
  z-index: 4;
  width: 100%;
  overflow: hidden;
}

.pop_up__floor__select_item {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 4px 20px;
  height: 40px;
  color: $white;
  font-size: $secondaryTextSize;
  background-color: $topCardBG;
  border-bottom: 2px solid $cardBG;
  transition: background-color .1s ease;
  user-select: none;
  &:first-child {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  &:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:hover {
    background-color: $cardListDivider;
  }
  &:active {
    background-color: $topCardBG;
  }
}

.title__floor__select,
.title__rooms_group_type {
  @extend .secondary_text;
  @extend .text__wrapper;
  flex: 1;
  margin-right: 8px;
}

.container__title__rooms_group_type {
  display: flex;
  align-items: center;
  height: 48px;
  background-color: $topCardBG;
  padding: 12px 20px;
  border-radius: 5px;
  margin: 2px;
  cursor: pointer;
}

.room_card__add_marker_btn {
  display: flex;
  opacity: 1;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #0079C1;
  margin-left: 6px;
  transition: background-color .2s ease, opacity .2s ease;
}

.room_card__add_marker_btn__active {
  @extend .room_card__add_marker_btn;
  background-color: $greenMarker;
}

.room_card__add_marker_btn__disabled {
  @extend .room_card__add_marker_btn;
  background-color: $lightBtnBG;
  opacity: .6;
  cursor: not-allowed;
  & .icon__add_white {
    transition: opacity .2s ease;
    cursor: inherit;
    opacity: .5;
  }
}

.room_card__edit_marker_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: .5px solid $blue;
  background-color: transparent;
  transition: border .2s ease;
  & .icon__edit__svg {
    & svg path {
      transition: fill .2s ease;
      fill: $blue;
    }
  }
}

.room_card__edit_marker_btn__active {
  @extend .room_card__edit_marker_btn;
  border: .5px solid $greenMarker;
  transition: border .2s ease;
  & .icon__edit__svg {
    & svg path {
      transition: fill .2s ease;
      fill: $greenMarker;
    }
  }
}

.btn__add_map {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 160px;
  margin: 0 auto;
  cursor: pointer;
  .icon__add_inactive {
    height: 60px;
    margin-bottom: 20px;
    & svg {
      width: 60px;
      height: 60px;
    }
  }
}

.map-screen__button-wrap {
  margin-top: calc(10rem/16);
  background-color: #1A1F27; 
  width: 100%;
}

.simple-btn_with-background {
  width: 100%;
  background-color: #0079C1;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #FFF;
  font-size: calc(14rem/16);
  transition: all 0.3s;
  &:hover {
    filter: contrast(150%);
  }
}