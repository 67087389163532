@import '../../styles/constants.scss';

.marker {
  width: 34px;
  height: 34px;
  position: absolute;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: white;
  transition: background-color 0.2s ease;
  line-height: 1;
  pointer-events: auto;
  transition: all .3s ease;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4), 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  & .icons_text {
    height: 70%;
    width: 70%;
    margin: auto;
    color: white;
    transition: color .2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > div:not(.marker__inner) {
    height: 60%;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: auto;
  }
  &:hover {
    z-index: 4;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4), 0 4px 12px rgba(0, 0, 0, 0.3);
  }
  &__inner {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    &-image {
      width: 60%;
      margin: auto;
      height: 60%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  &__active {
    @extend .marker;
    background-color: $blue;
    & .icons_text {
      color: $white;
    }
  }
}

.cursor-indicator {
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: $blue;
  position: fixed;
  pointer-events: none;
  border: 3px solid $lightGray;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.canvas-map-wrap {
  position: relative;
  cursor: grab;
  &.hover {
    cursor: move;
   }
  &.click {
    cursor: pointer;
   }
  &.cell {
   cursor: cell;
  }
  & .marker-hover {
    position: absolute;
    min-height: 5rem;
    z-index: 50;
    transform: translate(-50%);
    min-width: 20%;
  }
  & .marker-click {
    position: absolute;
    z-index: 2;
    transform: translate(-50%, -120%);
    min-width: 20%;
  }

  &.dragging {
    cursor: grabbing;
  }
}

.map-point-info__wrap {
  color: #000;
  width: 25%;
  position: absolute;
  top: 100;
  z-index: 2;
  background-color: #FBFBFB;
  border-radius: 0.4rem;
  overflow: hidden;
  box-shadow: 0.35rem 0.5rem 0.5rem rgba(0, 0, 0, 0.25);
  transform: translate(-50%, -102.5%);
}

.map-point-info__image-wrap {
  overflow: hidden;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
}

.map-point-info__image {
  background-size: cover;
  transition: all 0.5s; 
  min-height: 10rem;
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    transform: scale(1.2);
  }
}

.map-point-info__text-wrap {
  padding: 8px;
  flex: 1;
  width: 70%;
  display: flex;
  flex-direction: column;
  cursor: initial;
  &:hover {
    background-color: initial !important;
  }
}

.map-point-info__title {
  font-size: $mainTextSize;
}

.map-point-info__button-wrapper {
  min-height: 3rem;
  border-top: 1px solid #C4C4C4;
  display: flex;
}

.map-point-info__button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  display: flex;
  span {
    margin: auto;
  }
  &:first-child {
    border-right: 1px solid #C4C4C4;
    border-radius: 0 0 0 8px;
  }
  &:last-child {
    border-right: 1px solid #C4C4C4;
    border-radius: 0 0 8px 0;
  }
  transition: background-color .2s ease;
  &:hover {
    background-color: $lightBlue;
  }
}

.map-bucket-wrap {
  z-index: 1;
  width: 4rem;
  height: 4rem;
  position: absolute;
  right: 3rem;
  border-radius: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #202630;
  opacity: 0.8;
}

.map-buttons-wrap {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 3; 
}

.map-modal-wrap {
  box-shadow: 0 0 10px #333;

}

.map-modal-title {
  text-align: center; 
  color: #333; 
  font-size: calc(14rem/16);
}
