
.bookingForm {
  display: flex;
  width: 100%
}

.bookingForm__rangePicker {
  width: 40%;
}

.bookingForm__simpleSelectItems {
  width: 60%;
  position: relative;
}
