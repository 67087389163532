@import 'index';
@import 'constants';

@mixin transition($time) {
  -webkit-transition: box-shadow $time;
  -moz-transition: box-shadow $time;
  -o-transition: box-shadow $time;
  transition: box-shadow $time;
}

.input_label {
  font-size: $mainTextSize;
  color: #a7a7a7;
  user-select: none;
}

.ant-select {
  font-size: 14px;
  border-radius: 4px;
  //margin-bottom: 6px;
}

.ant-select-selection {
  width: 100%;
  background-color: $darkBlueBG;
  border: 0;
  color: #a7a7a7;
}

.ant-select-selection--single {
  height: 32px;
  background-color: #0e131a !important;
  border: 0;
}

.ant-select-disabled {
  & .ant-select-selection {
    color: $lightBtnBG;
  }
  & .ant-select-selection--single {
    background-color: $transparentDarkBlue !important;
    & .ant-select-arrow {
      color: $darkDivider;
    }
  }
  .ant-select-selection__placeholder {
    display: inline !important;
    color: $secondaryText;
  }
  border-radius: 4px;
  border: 0;
}

.ant-select-auto-complete.ant-select,
.ant-input[disabled] {
  color: $secondaryText;
}

.ant-select-selection__placeholder {
  color: $secondaryText !important;
}

.ant-select-search__field__wrap .ant-input-disabled {
  background-color: transparent !important;
}

.ant-select-arrow {
  color: #0079c1;
}

.ant-form-item-label {
  text-align: left;
  font-size: 14px;
  line-height: 14px;
  @media (max-width: 575px) {
    padding: 0;
    text-align: left;
    font-size: 14px;
    line-height: 14px;
  }
}

.ant-form-item-label > label {
  color: $labelText;
}

.ant-form-item-control {
  margin-top: 8px;
  line-height: 0;
}

.ant-form-item {
  margin: 2px 0;
}

.multi_input__new_item,
.ant-input,
.ant-input-disabled,
.ant-time-picker-input {
  font-size: 14px;
  box-sizing: border-box;
  background-color: $darkBlueBG;
  border: 0;
  color: $mainText;
  border-radius: 4px;
}

.ant-input-affix-wrapper {
  border-radius: 4px;
  overflow: auto;
  height: fit-content;
  & .ant-input {
    margin-bottom: 0;
  }
}

.ant-calendar-picker {
  border-radius: 4px;
}

.ant-calendar-picker-input.ant-input {
  padding: 4px 8px;
}

.ant-input::placeholder {
  color: $secondaryText;
}

.ant-input-disabled {
  & input {
    color: $secondaryText !important;
  }
  &::placeholder {
    color: $lightBtnBG !important;
  }
}

.multi_input__new_item,
.ant-input {
  margin-bottom: 2px;
}

.ant-time-picker-input {
  margin-bottom: 10px;
}

.ant-input:focus,
.ant-time-picker-input:focus {
  outline: none;
}

.ant-form-item-label > label::after {
  content: '';
  @media (max-width: 575px) {
    display: inline;
  }
}

textarea.ant-input,
textarea.ant-time-picker-input {
  resize: none;
}

.ant-tag {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 15px;
  background: $topCardBG;
  border: 0.5px solid transparent;
  color: #a7a7a7;
  border-radius: 12px;
  height: 24px;
  margin-bottom: 10px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background: $divider;
  }
}
.info__block__display .ant-tag {
  padding-right: 15px;
}

.add_tag_btn {
  height: 24px;
  width: 24px;
  background: #1f2631;
  padding-left: 0;
  padding-right: 0;
  margin-right: 0;
  transition: box-shadow 0.3s ease;
  &:hover {
    box-shadow: 0 0 4px rgba(0, 121, 193, 0.8);
    transition: box-shadow 0.3s ease;
  }
}

.add_tag__input {
  box-sizing: border-box;
  height: 23px;
  width: 78px;
  border-radius: 12px;
  font-size: 12px;
  margin-bottom: 10px;
}

.add_tag__input:focus {
  outline: none;
}

.ant-form-item-children,
.ant-form-item-children div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.select__date {
  display: inline;
  margin-right: 2%;
}

.select__date:last-child {
  margin-right: 0;
}

.date_picker {
  margin-right: 5%;
  cursor: pointer;
}

::selection {
  color: $white;
  background: $blue;
}

.ant-calendar-picker-clear,
.ant-calendar-picker-icon {
  display: none;
  height: 32px;
  margin-top: -11px;
  .date_picker__single & {
    margin-top: -7px;
  }
}

.ant-calendar-picker + .validation_block {
  & .validation_msg {
    bottom: -5px !important;
  }
}

.ant-time-picker-icon {
  margin-top: -11px;
}

.ant-time-picker {
  position: relative;
}

.ant-list-split .ant-list-item {
  border-bottom: 0;
  padding: 12px 6px;
}

.ant-list {
  font-size: 16px;
  padding-top: 2px;
}

.lectures_list__item,
.lectures_list__item__active {
  font-family: 'DIN Pro Light';
  background: #1f2631;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  height: 60px;
  color: #a7a7a7;
  border-radius: 5px;
  margin-bottom: 2px;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  transition: box-shadow 0.4s ease;
}

.lectures_list__item__active {
  transition: box-shadow 0.4s ease;
  box-shadow: 0 0 6px $blue;
  position: relative;
  z-index: 2;
  & .icon__expand {
    transform: rotate(180deg);
    transition: transform 0.4s ease-out;
  }
}

.lectures_list__item {
  &:hover {
    z-index: 2;
    transition: box-shadow 0.4s ease;
    box-shadow: 0 0 6px $blue, 0 4px 10px $semiTransparentBlack;
  }
}

.lectures_list__title,
.lectures_list__speaker,
.lectures_list__time,
.lectures_list__speaker_block {
  @extend .text__wrapper;
}

.lectures_list__speaker {
  font-size: $secondaryTextSize;
}

.lectures_list__id {
  @extend .text__wrapper;
  display: block;
  flex: 1;
  height: auto;
  text-align: left;
  margin-left: 14px;
  max-width: 15%;
  min-width: 32px;
  font-size: $secondaryTextSize;
}

.lectures_list__title {
  line-height: 2;
  display: block;
  flex: 9;
  text-align: left;
  padding-right: 4%;
}

.lectures_list__time {
  display: inline-block;
  flex: 3;
  text-align: left;
  padding: 0 2% 0;
  font-size: $secondaryTextSize;
}

.lectures_list__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  &:last-child {
    margin-right: 14px;
  }
}

.lectures_list__speaker_block {
  display: flex;
  align-items: center;
  flex: 4;
  padding: 0 2%;
  border-left: 1px solid $cardListDivider;
  border-right: 1px solid $cardListDivider;
}

.divider {
  height: 1px;
  width: 100%;
  background: #242b37;
}

.ant-timeline-item-tail {
  border-left: 1px solid $darkDivider;
}

.ant-timeline-item-head-blue {
  border: none;
}

.ant-timeline-item-head {
  background-color: $green;
  width: 8px;
  height: 8px;
}

//.ant-timeline-item:last-child .ant-timeline-item-tail {
//  display: none;
//}

.list {
  overflow: scroll;
}

.files_list__item {
  display: flex;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  align-items: center;
  padding: 8px 14px 8px 4px;
  height: 50px;
  width: 100%;
  border-bottom: 0.5px solid $darkDivider;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border-bottom: none;
  }
}

.list__item__title {
  font-size: $secondaryTextSize;
  color: $white;
}

.list__emptiness_message {
  width: 100%;
  display: block;
  font-size: $secondaryTextSize;
  color: $secondaryText;
  text-align: center;
  user-select: none;
}

.ant-message-notice-content {
  background: $semiTransparentBlack;
  color: $mainText;
}

.ant-select-search__field {
  overflow: hidden;
  width: 100%;
}

.ant-select-search__field__wrap {
  overflow: hidden;
  & .ant-input {
    padding: 0;
    box-shadow: none;
    margin-bottom: 0;
  }
}

.ant-select-search {
  overflow: hidden;
  width: 100%;
}

.ant-select-selection__rendered {
  line-height: 32px;
  height: 32px;
  width: 100%;
  overflow: hidden !important;
  margin: 0 24px 0 13px;
  .ant-select-auto-complete & {
    padding: 0 12px;
  }
  & ul {
    width: 100%;
  }
}

.ant-select-auto-complete.ant-select .ant-input {
  border-width: 0;
}

.ant-select-auto-complete.ant-select .ant-input:focus {
  border-right-width: 0 !important;
}

.ant-select-auto-complete.ant-select .ant-input:hover {
  border-width: 0 !important;
}

.ant-select-auto-complete.ant-select,
.ant-select-selection__rendered {
  height: fit-content;
  overflow: auto;
}

.input__file_uploader {
  display: none;
}

.input__upload_container {
  background: $darkBlueBG;
  border-radius: 5px;
  height: 32px;
  display: flex;
  width: 100%;
  margin-bottom: 14px;
  justify-content: flex-end;
}

.input__upload_button {
  user-select: none;
  width: 30%;
  max-width: 30%;
  display: flex;
  background: $topCardBG;
  flex: 1;
  border-radius: 0 4.5px 4.5px 0;
  align-items: center;
  justify-content: center;
  color: $mainText;
  font-size: $secondaryTextSize;
  transition: background 0.3s ease-out, color 0.3s ease-out;
  cursor: pointer;
  &:hover {
    background: $blue;
    color: $white;
    transition: background 0.5s ease-out, color 0.5s ease-out;
  }
  &_disabled {
    @extend .input__upload_button;
    cursor: not-allowed;
    &:hover {
      background: $topCardBG;
      color: $mainText;
    }
  }
}

.input__upload_placeholder {
  @extend .text__wrapper;
  display: flex;
  align-items: center;
  flex: 2;
  color: $mainText;
  font-size: $secondaryTextSize;
  margin-left: 15px;
  cursor: default;
}

.input__uploaded_file {
  overflow: auto;
  flex-direction: column;
  align-items: flex-start;
  flex: 10;
}

.input__file_name {
  @extend .text__wrapper;
  max-width: 100%;
  color: $mainText;
  font-size: $secondaryTextSize;
  display: block;
  width: 100%;
}

.input__file_info {
  color: $secondaryText;
  font-size: $smallTextSize;
  display: block;
  width: 100%;
}

.filter__tabs {
  display: flex;
  width: fit-content;
  min-height: 35px;
  height: 35px;
  & input {
    display: none;
  }
  & label {
    cursor: pointer;
    line-height: 15px;
  }
}

.filter__tabs__form_type,
.filter__tabs__account_type {
  @extend .filter__tabs;
  padding-right: 30px;
  width: 100%;
}

.filter-tabs__group-mode {
  @extend .filter__tabs;
  padding-left: 30px;
  margin-bottom: 30px;
}

.filter__tab {
  @extend .main_text;
  flex: 1;
  font-family: 'DIN Pro';
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: $darkDivider;
  padding: 0 20px;
  white-space: nowrap;
  user-select: none;
  transition: color 0.3s ease-out;
  &:first-child {
    border-radius: 18px 0 0 18px;
  }
  &:last-child {
    border-width: 1px 1px 1px 1px;
    border-radius: 0 18px 18px 0;
  }
  &:hover {
    transition: color 0.3s ease-out;
    color: $white;
  }
  &:active {
    background-color: $divider;
  }
}

.filter__tab__active {
  @extend .filter__tab;
  display: block;
  font-size: 16px;
  background-color: $darkDivider;
  color: $white !important;
}

.filter__tab__disabled {
  @extend .filter__tab;
  display: block;
  font-size: 16px;
  color: $lightBtnBG !important;
}

.label {
  display: block;
  font-size: $secondaryTextSize;
  color: $labelText;
}

.info__block {
  padding: 5% 0 5%;
  border-bottom: 0.5px solid $darkDivider;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    border: 0;
  }
  & .filter_tag__block {
    margin-left: 0;
    margin-bottom: 5px;
  }
}

.info__block__display {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.validation_msg {
  font-size: $smallTextSize;
  color: $red;
  position: absolute;
  bottom: -5px;
  letter-spacing: 0.2px;
}

.validation_block {
  width: 100%;
  position: relative;
  margin-bottom: 3px;
}

.underlined_title__block {
  padding-bottom: 4px;
  margin-bottom: 12px;
  border-bottom: 0.5px solid $blue;
}

.top_margin {
  margin-top: 20px;
}

.ant-radio-wrapper {
  display: flex;
  align-items: center;
  margin: 4px 0;
  &:first-child {
    margin: 5px 0 8px;
  }
  &:last-child {
    margin: 8px 0 5px;
  }
  & span {
    display: block;
    margin-right: 5px;
    @extend .secondary_text;
  }
}

.ant-radio-group {
  display: flex;
  flex-direction: column !important;
}

.ant-radio,
.ant-radio-input {
  width: 15px;
  height: 15px;
}

.ant-radio-inner {
  background: $cardBG;
  border: 1px solid $labelText;
  .ant-radio:checked & {
    border-color: $blue;
  }
}

.multi_input__block {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.multi_input_item__block {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 4px 0;
  & .ant-input-suffix {
    opacity: 0.7;
    transition: opacity 0.5s;
  }
  &:hover .ant-input-suffix {
    opacity: 1;
    transition: opacity 0.5s;
  }
}

.multi_input_item__index {
  @extend .secondary_text;
  line-height: 14px;
  margin-right: 25px;
  user-select: none;
}

.multi_input__new_item {
  flex: 1;
  display: flex;
  align-items: center;
  height: 35px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  @include transition(0.5s);
  & span {
    display: block;
    margin-left: 11px;
  }
  &:hover {
    @include transition(0.5s);
    box-shadow: 0 0 8px rgba(0, 121, 193, 0.5);
  }
  &:active {
    @include transition(0.2s);
    box-shadow: 0 0 6px rgba(0, 121, 193, 0.4);
  }
}

.ant-input-suffix {
  cursor: pointer;
}

.ant-calendar-range-picker-separator {
  color: $mainText;
  font-size: $mainTextSize;
}

.ant-calendar-range-picker-input {
  text-align: left;
  width: 100%;
  max-width: calc(50% - 5px);
}

.ant-calendar-range-picker-input::placeholder {
  color: $labelText;
}

.ant-input-number-input-wrap {
  border-radius: 4px;
  background-color: $darkBlueBG;
}

.ant-input-number-input {
  color: $mainText;
  font-size: 14px;
  background-color: $darkBlueBG;
  &::placeholder {
    color: $labelText;
  }
}

.ant-input-number {
  background-color: transparent;
  height: 32px;
  border-radius: 4px;
  border: 0;
  transition: background-color 0.2s ease;
  .ant-input-number-input {
    transition: background-color 0.2s ease;
    background-color: $transparentDarkBlue !important;
  }
  .ant-input-number-input-wrap {
    height: 100%;
  }
  &-disabled {
    background-color: $transparentDarkBlue !important;
    .ant-input-number-input,
    .ant-input-number-input-wrap {
      transition: background-color 0.2s ease;
      color: $lightBtnBG !important;
      background-color: transparent !important;
    }
  }
}

.ant-input-number-handler-wrap {
  background: $darkBlueBG;
  transition: background 0.5s ease-out;
  opacity: 1;
  border: 0;
  &:hover {
    background: $divider;
    color: $white;
    transition: background 0.5s ease-out;
  }
}

.ant-input-number-handler {
  color: $blue;
}

.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  color: $blue;
}

.ant-input-number-handler-down,
.ant-input-number-handler-up {
  border: 0;
  &:active {
    background: rgba(92, 111, 137, 0.3);
  }
}

.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: $white;
}

.ant-time-picker-clock-icon {
  opacity: 0.5;
}

.ant-time-picker-panel {
  width: fit-content;
}

.ant-time-picker-panel-inner {
  background-color: $darkBlueBG;
  color: $mainText;
  font-family: 'DIN Pro Light';
}

li.ant-time-picker-panel-select-option-selected {
  background-color: $darkBlueBG;
}

.ant-time-picker-panel-select {
  border-left: 1px solid $divider;
  & li:hover {
    background-color: $cardListDivider;
    color: white;
  }
}

.ant-time-picker-panel-input-wrap,
.ant-calendar-input-wrap,
.ant-calendar-header {
  border-bottom: 1px solid $divider;
}

.ant-calendar-body {
  border-bottom: 0 solid $divider;
}

.ant-calendar-footer {
  border-top: 1px solid $divider;
}

.ant-time-picker-panel-input,
.ant-calendar-input,
.ant-calendar-range .ant-calendar-input {
  background-color: $darkBlueBG;
  font-family: 'DIN Pro';
  color: white;
}

.ant-calendar {
  background-color: $darkBlueBG;
  border: 1px solid $divider;
}

.ant-calendar-header,
.ant-calendar-month-select,
.ant-calendar-year-select,
.ant-calendar-year-panel-decade-select-content,
.ant-calendar-month-panel-year-select-content {
  color: $mainText !important;
  font-family: 'DIN Pro Light';
  &:hover {
    color: $blue !important;
  }
}
.ant-calendar-next-month-btn,
.ant-calendar-prev-month-btn,
.ant-calendar-next-year-btn,
.ant-calendar-prev-year-btn,
.ant-calendar-year-panel-next-decade-btn,
.ant-calendar-year-panel-prev-decade-btn,
.ant-calendar-month-panel-next-year-btn,
.ant-calendar-month-panel-prev-year-btn {
  &:hover::before {
    color: $blue !important;
    border: 0 solid $blue !important;
    border-width: 1.5px 0 0 1.5px !important;
  }
  &:hover::after {
    color: $blue !important;
    border: 0 solid $blue !important;
    border-width: 1.5px 0 0 1.5px !important;
  }
}

.ant-calendar-column-header-inner {
  color: $white;
  font-family: 'DIN Pro';
}

.date_pick {
  & .ant-calendar-picker + .validation_block .validation_msg {
    bottom: -4px !important;
  }
}

.ant-calendar-date {
  font-family: 'DIN Pro Light';
  color: $mainText;

  .ant-calendar-last-month-cell &,
  .ant-calendar-next-month-btn-day & {
    color: $cardListDivider;
    &:hover {
      color: $mainText;
    }
  }
  .ant-calendar-selected-day & {
    background-color: $blue;
    color: $white;
  }
  .ant-calendar-in-range-cell & {
    background-color: $divider;
    color: $white;
  }
  &:hover {
    background-color: $divider;
    color: $white;
  }
}

.ant-calendar-today .ant-calendar-date {
  border: 1px solid $blue;
}

.ant-calendar-today-btn,
.ant-calendar-time-picker-btn {
  font-family: 'DIN Pro';
  color: $blue;
}

.ant-calendar .ant-calendar-ok-btn {
  background-color: $blue;
  border: 1px solid $blue;
}

.ant-calendar-year-panel,
.ant-calendar-decade-panel,
.ant-calendar-month-panel {
  background-color: $darkBlueBG;
  font-family: 'DIN Pro Light';
}

.ant-calendar-year-panel-header,
.ant-calendar-decade-panel-header,
.ant-calendar-month-panel-header {
  border-bottom: 1px solid $divider;
}

.ant-calendar-year-panel-year {
  font-family: 'DIN Pro Light';
  color: $mainText;
  .ant-calendar-year-panel-selected-cell & {
    background-color: $blue;
  }
  .ant-calendar-year-panel-last-decade-cell &,
  .ant-calendar-year-panel-next-decade-cell & {
    color: $cardListDivider;
  }
  &:hover {
    background-color: $divider;
    color: $white !important;
  }
}

.ant-calendar-decade-panel-decade {
  font-family: 'DIN Pro Light';
  color: $mainText;
  &:hover {
    background-color: $divider;
    color: $white !important;
  }
  .ant-calendar-decade-panel-selected-cell & {
    background-color: $blue;
  }
  .ant-calendar-decade-panel-last-century-cell &,
  .ant-calendar-decade-panel-next-century-cell & {
    color: $cardListDivider;
  }
}

.ant-calendar-month-panel-month {
  font-family: 'DIN Pro Light';
  color: $mainText;
  .ant-calendar-month-panel-selected-cell & {
    background-color: $blue;
  }
  .ant-calendar-month-panel-cell-disabled & {
    background-color: rgba(46, 52, 62, 0.2);
    transition: background-color 0.3s ease-in-out;
    color: $secondaryText;
    &:hover {
      color: $secondaryText !important;
      transition: background-color 0.3s ease-in-out;
      background-color: rgba(46, 52, 62, 0.4);
    }
  }
  &:hover {
    background-color: $divider;
    color: $white !important;
  }
}

.ant-calendar-tbody {
  .ant-calendar-selected-day & {
    background-color: $blue;
    color: $white;
  }
}

.ant-calendar-range .ant-calendar-in-range-cell::before {
  background: rgba(46, 52, 62, 0.3);
}

.ant-calendar-range .ant-calendar-body,
.ant-calendar-month-panel-body,
.ant-calendar-year-panel-body,
.ant-calendar-decade-panel-body {
  border-top: 1px solid $divider !important;
}

.ant-time-picker-panel-input:placeholder-shown {
  @extend .text__wrapper;
  display: block;
  font-family: 'DIN Pro Light';
  color: $divider !important;
}

.ant-select-dropdown-menu-item,
ant-select-dropdown-menu {
  background: $darkBlueBG;
  color: $mainText;
}

.ant-select-dropdown {
  background-color: $darkBlueBG;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item-selected {
  background-color: $darkBlueBG;
}

.ant-select-dropdown-menu-item-active {
  background-color: $divider;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: $topCardBG;
  color: $white;
}

.ant-select-dropdown-menu-item-disabled:hover,
.ant-select-dropdown-menu-item-disabled {
  color: $secondaryText;
  background-color: $darkBlueBG;
}

.ant-select > ul > li > a,
.ant-select-selection__choice {
  background-color: $divider;
  margin-bottom: 3px;
}

.ant-calendar-time-picker-btn {
  color: $blue;
}

.ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn-disabled {
  font-family: 'DIN Pro';
  color: $secondaryText;
}

.ant-calendar .ant-calendar-ok-btn-disabled {
  background-color: $cardListDivider;
  border-color: $cardListDivider;
}

.ant-calendar-range-right .ant-calendar-time-picker-inner {
  border-left: 1px solid $divider;
}

.ant-calendar-range-left .ant-calendar-time-picker-inner {
  border-right: 1px solid $divider;
}

.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-combobox,
.ant-calendar-time-picker {
  background-color: $darkBlueBG;
}

li.ant-calendar-time-picker-select-option-selected {
  background-color: $cardListDivider;
  color: $white !important;
  font-family: 'DIN Pro' !important;
}

.ant-calendar-time-picker-select {
  border-right: 1px solid $divider;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-combobox {
  border-top: 1px solid $divider;
}

.ant-calendar-time-picker-select li {
  font-family: 'DIN Pro Light';
  color: $mainText;
  &:hover {
    background-color: $divider;
    color: $white;
  }
}

.ant-calendar-time .ant-calendar-day-select {
  color: $mainText;
}

.ant-calendar-range-middle {
  color: $mainText;
}

.ant-select-selection--single .ant-select-selection__rendered {
  overflow: auto;
}

.ant-calendar-disabled-cell {
  & .ant-calendar-date {
    background: $semiTransparentBlack;
    color: $darkDivider;
  }

  & .ant-calendar-date:hover {
    background: $semiTransparentBlack;
    color: $darkDivider;
  }
}

.ant-calendar-time-picker-select-option-disabled {
  color: $darkDivider !important;
}

.timepicker_decorator {
  width: 10%;
  margin-bottom: 26px;
  justify-content: center;
  align-items: center;
  color: $mainText;
}

.ant-tooltip-open {
  word-break: break-all;
}

.ant-checkbox {
  height: 14px;
}

.ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  height: 17px;

  span {
    @extend .secondary_text;
    display: inline-block;
    user-select: none;
  }

  .ant-checkbox + span {
    line-height: 10px !important;
    padding: 0 8px 0 15px !important;
  }
}

.ant-checkbox-disabled {
  .ant-checkbox-inner {
    border-color: #7a7d82 !important;
    background-color: #191f28;
  }
}

.ant-checkbox-inner {
  background-color: $cardBG;
  border-color: $labelText;
}

.labeled_field__label {
  @extend .label;
  @extend .margin_top;
  margin-bottom: 6px;
  user-select: none;
}

.labeled_field__block__bordered {
  padding-bottom: 12px;
  border-bottom: 0.5px solid $divider;
  &:last-child {
    border: 0;
  }
  & .labeled_field__label {
    margin-top: 6px;
  }
}

.search_field {
  background-color: $topCardBG;
  min-width: 25px;
  width: 25px;
  height: 25px;
  border-radius: 13px;
  margin: 10px 4px;
  padding-right: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: width 0.2s ease-out, color 0.1s ease, background-color 0.2s ease !important;
  & .icon__search {
    transition: opacity 0.3s ease;
    opacity: 0.5;
    margin: 1px 7px 0 7px;
  }
  & .search_field__label {
    margin-right: 7px;
    user-select: none;
  }
  &:hover {
    width: 80px;
    transition: width 0.3s ease-in-out, color 0.1s ease;
    & .icon__search {
      transition: opacity 0.3s ease;
      opacity: 1;
    }
    & .search_field__label {
      opacity: 1;
      width: 30px;
      margin-right: 29px;
      transition: opacity 0.1s ease, width 0.1s ease;
    }
  }
  & .ant-select-auto-complete.ant-select .ant-select-selection--single {
    margin-top: 3px;
    height: 23px;
    border-radius: 15px;
    position: relative;
  }
  & .ant-form-item {
    width: 100%;
  }
  & .ant-select-auto-complete.ant-select .ant-input {
    padding-bottom: 9px;
  }
  & .icon__close {
    position: absolute;
    top: 7px;
    right: 8px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  & .ant-select-auto-complete .ant-select-selection__rendered {
    padding: 0 8px 0 4px;
  }
  & .ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
    margin-left: 4px;
  }
  & .ant-input {
    height: 25px;
    border-radius: 10px;
  }
  & .validation_block {
    height: 25px;
  }
  & .ant-form-item-control,
  & .ant-input,
  & .ant-input:focus {
    margin: 0;
    border-color: transparent;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
    background-color: transparent;
  }
}

.search_field__label {
  @extend .secondary_text;
  opacity: 0;
  width: 0;
  transition: opacity 0.1s ease, width 0.1s ease;
}

.search_field__active {
  @extend .search_field;
  transition: width 0.3s ease-out, color 0.1s ease, background-color 0.2s ease !important;
  width: 100% !important;
  border: 1px solid $blue;
  background-color: $darkBlueBG;
  &:hover {
    width: 100% !important;
  }
}

.search_field__large {
  @extend .search_field;
  min-width: 35px;
  width: 35px;
  height: 35px;
  border-radius: 18px;
  margin: 0 17px 0 0;
  //margin: 0 17px 0 20px;
  align-items: center;
  transition-delay: 0s;
  outline: none !important;
  outline-width: 0;
  background-color: $topCardBG;
  transition: width 0.2s ease-out, color 0.1s ease,
  background-color 0.3s ease 0.1s !important;
  & .icon__search {
    max-height: 25px;
    height: 25px;
    margin: 13px 7px 0 11px;
    background-size: contain;
  }
  & .search_field__label {
    line-height: 35px;
    margin-right: 7px;
    margin-left: 10px;
  }
  .margin_top__small {
    display: none;
  }
  & input {
    height: 32px;
    outline-width: 0;
  }
  & .validation_block {
    margin-bottom: 0;
  }
  & .ant-form-item-control,
  & .ant-input,
  & .ant-input:focus {
    margin: 0;
    border-color: transparent;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
    background-color: transparent;
  }
  & .ant-form-item-children {
    outline: none;
  }
  &:hover .search_field__label {
    opacity: 1;
    width: 30px;
    transition: opacity 0.1s ease, width 0.1s ease;
    transition-delay: 0.2s;
  }
  & .icon__close {
    position: relative;
    top: unset;
    right: unset;
    cursor: pointer;
    margin-top: 5px;
    margin-right: 9px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &:hover {
    width: 96px;
  }
}

.search_field__large__active {
  @extend .search_field__large;
  transition: width 0.3s ease-out, color 0.1s ease, background-color 0.2s ease,
    border 0.3s ease !important;
  width: 100% !important;
  border: 1px solid $labelText;
  background-color: $cardBG;
  &:hover {
    width: 100% !important;
  }
  &.disabled {
    cursor: not-allowed;
    background-color: $semiTransparentDarkBlue !important;
    input {
      background-color: transparent !important;
    }
  }
}

.ant-calendar-picker-input {
  text-align: left;
  display: flex !important;
}

.loading_animation {
  & .input__upload_placeholder {
    display: none;
  }
  height: 32px;
  background-color: $divider;
  background-size: 30px 30px;
  background-image: linear-gradient(
    135deg,
    rgb(50, 59, 73) 25%,
    transparent 25%,
    transparent 50%,
    rgb(50, 59, 73) 50%,
    rgb(50, 59, 73) 75%,
    transparent 75%,
    transparent
  );
  animation: animate-stripes 0.6s linear infinite;
  animation-duration: 1s;
}

@keyframes animate-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 60px 0;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: $mainText !important;
  -webkit-box-shadow: 0 0 0px 1000px $darkBlueBG inset !important;
}

.ant-select-selection--multiple .ant-select-selection__choice {
  background-color: $topCardBG;
  color: $white;
  border: 0;
  border-radius: 4px;
  padding: 1px 24px 0 14px;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: $mainText;
  &:hover {
    color: $red;
  }
}

.ant-select-selection--multiple .ant-select-selection__rendered {
  margin-bottom: 0;
}

.ant-select-selection--multiple {
  padding-bottom: 0;
}

.ant-calendar .ant-calendar-ok-btn:not([disabled]):hover {
  background-color: #2b3647;
  border-color: #2b3647;
}

// Timeline

.timeline_marker__yellow {
  -webkit-animation: fade 0.4s;
  animation: fade 0.4s;
  animation-name: fade;
  transition: opacity 0.3s ease-out;
  & .ant-timeline-item-head {
    background-color: $yellow;
  }
}

.timeline_marker__green {
  -webkit-animation: fade 0.4s;
  animation: fade 0.4s;
  animation-name: fade;
  transition: opacity 0.3s ease-out;
  & .ant-timeline-item-head {
    background-color: $greenMarker;
  }
}

.ant-timeline-item:first-child {
  padding-top: 4px;
}

.ant-input-group-addon {
  background-color: $divider;
  border-width: 0;
  cursor: pointer;
  &:last-child {
    border-left: 0.5px solid $lightBtnBG;
  }
}

.ant-input-suffix {
  height: 100%;
  margin-right: -12px;
  width: fit-content;
  padding: 8px;
  .wide_suffix & {
    padding: 0;
  }
}

.ant-input-disabled {
  color: $lightBtnBG !important;
  background-color: $transparentDarkBlue !important;
}

.ant-radio-wrapper-disabled {
  & span {
    color: $lightBtnBG !important;
  }
  & .ant-radio-disabled {
    & .ant-radio-inner {
      background-color: $transparentDarkBlue !important;
      border-color: $lightBtnBG !important;
      &:after {
        background-color: $lightBtnBG;
      }
    }
  }
}

.ant-empty-image {
  display: none;
}

.notification_antd {
  background-color: $errorNotificationBg;
  color: $white;
  font-family: 'DIN Pro Light';
  & .ant-notification-notice-message {
    color: $white;
  }
  & svg {
    fill: $white;
  }
  & .ant-notification-close-x {
    color: $white;
  }
}

.notification_antd___succeed {
  @extend .notification_antd;
  background-color: $succeedNotificationBg;
}

.time_range_picker {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 8px;
  & .ant-time-picker-icon {
    display: none;
  }
  & > div {
    width: 112px;
    & > span {
      width: 100%;
    }
  }
  & > span {
    color: $labelText;
    align-self: center;
    margin: 0 7px 14px;
  }
}

.ant-switch {
  background-color: #3e4959;
  &.ant-switch-checked {
    background-color: $green;
  }
  &:after {
    background-color: $lightGray;
  }
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: $blueLink;
  border-color: $blueLink;
  &:after {
    width: 6px;
    height: 6px;
    background-color: #fff;
  }
}

.ant-form-item-row {
  display: flex;
  align-items: center;
  .ant-form-item-control-wrapper {
    flex: 1;
  }
}

.ant-slider-track {
  background-color: $blue;
  &:hover {
    background-color: tomato;
  }
}

.ant-slider-handle {
  border-color: $blue;
}

.ant-slider {
  &:hover .ant-slider-track {
    background-color: $blue;
    // background-color: white;
  }
  &:hover .ant-slider-rail {
    // background-color: $blue;
    background-color: white;
  }
}
