$blue: #0079C1;
$blueLink: #1890ff;
$lightBlue: #E1ECF7;
$green: #31cb81;
$darkBlue: #003F76;
$yellow: #F7A833;
$yellowMarker: #FFCA41;
$greenMarker: #10CF5C;
$blueMarker: #0079C1;
$red: #CD4141;
$lightRed: #DA5252;
$purple: #B958F5;
$semiTransparentGreen: rgba(16,207,92, .5);
$semiTransparentRed: rgba(205, 65, 65, .5);
$errorNotificationBg: rgba(205, 65, 65, .8);
$succeedNotificationBg: rgba(0, 158, 74, 0.8);
$semiTransparentBlue: rgba(0,121,193, .7);
$semiTransparentBlack: rgba(7, 7, 7, 0.5);
$transparentDarkBlue: rgba(14, 19, 26, 0.5);
$semiTransparentDarkBlue: rgba(14, 19, 26, 0.7);
$darkBlueBG: #0E131A;
$cardBG: #191F28;
$divider: #242B37;
$darkDivider: #2E343E;
$cardListDivider: #2B3647;
$lightBtnBG: #323B49;
$white: #FFFFFF;
$mainText: #A7A7A7;
$lightGray: #FBFBFB;
$secondaryText: #4F5762;
$subtitle: #D6D6D6;
$chartTitle: #D3D3D3;
$labelText: #7A7D82;
$scrollThumb: #5C6F89;
$accentText: #0078BF;
$topCardBG: #1F2631;
$lightBlueBtnBG: #1F2631;
$lightBlueBtnBGHover: #212934;
$topCardBGActive: #1c232e;
$linkBlockBG: #1F2630;
$tooltipBG: #1B1F26;
$onAirBtnRedBG: #6e3f3f;
$onAirBtnRed: #FF0000;
$onAirBtnWhiteBG:#364153;
$onAirBtnWhite: #FFFFFF;

$subtitleSize: 18px;
$mainTextSize: 16px;
$secondaryTextSize: 14px;
$semiSecondaryTextSize: 13px;
$smallTextSize: 12px;
$h1TextSize: 32px;
$tooltipBG: rgba(15, 20, 27, 0.9);
$btnPaddingX: 25px;
$btnPaddingY: 0;


$defaultHtmlFontSize: 16px;
