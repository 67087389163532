@import 'constants';

.header {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.header__link {
  padding-left: 50px;
  padding-right: 30px;
}
.header__icon {
  padding-right: 10px;
  color: $blueLink;
}
