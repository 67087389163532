@import 'constants';

.btn {
  font-family: 'DIN Pro';
  display: flex;
  cursor: pointer;
  align-items: center;
  width: fit-content;
  width: -moz-fit-content;
  user-select: none;
}

@mixin btn_transition($time) {
  -webkit-transition: box-shadow $time;
  -moz-transition: box-shadow $time;
  -o-transition: box-shadow $time;
  transition: box-shadow $time;
}

%hover_blue {
  transition: box-shadow 0.2s ease-out;

  &:hover {
    box-shadow: 0 0 6px rgba(0, 121, 193, 0.5);
  }

  &:active {
    box-shadow: 0 0 2px rgba(0, 121, 193, 0.3);
  }
}

%hover_red {
  transition: box-shadow 0.2s ease-out;

  &:hover {
    box-shadow: 0 0 6px rgba(205, 65, 65, 0.5);
  }

  &:active {
    box-shadow: 0 0 2px rgba(205, 65, 65, 0.3);
  }
}

.bordered_btn {
  @include btn_transition(0.5s);
  @extend .btn;
  padding: 0 19px;
  justify-content: center;
  min-height: 35px;
  height: 35px;
  border-radius: 18px;
  border: 0.5px solid $blue;
  z-index: 1;
  position: relative;
  background: $cardBG;
  outline: none;
  transition: box-shadow 0.2s ease-out, opacity 0.3s ease-out;

  &:hover {
    @include btn_transition(0.5s);
    box-shadow: 0 0 8px rgba(0, 121, 193, 0.5);
  }

  &:active {
    @include btn_transition(0.2s);
    box-shadow: 0 0 2px rgba(0, 121, 193, 0.3);
  }

  & .icon__edit {
    width: 12px !important;
    min-width: 12px !important;
  }
}

.bordered_btn__flex__end {
  @extend .bordered_btn;
  align-self: flex-end;
  transition: width 0.2s ease;
}

.btn_solid {
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 19px;
  color: $white;
  background-color: $blue;
  border-radius: 18px;
  cursor: pointer;
  outline: none;
  @include btn_transition(0.5s);

  &:hover {
    @include btn_transition(0.5s);
    box-shadow: 0 0 8px rgba(0, 121, 193, 0.5);
  }

  &:active {
    @include btn_transition(0.2s);
    box-shadow: 0 0 2px rgba(0, 121, 193, 0.3);
  }
}

.bordered_btn__save_lecture {
  @extend .btn;
  @extend .bordered_btn;
}

.rounded-btn_blue {
  @extend .btn;
  @extend .bordered_btn;
  @include btn_transition(0.5s);
  background-color: $blue;
  color: $white;

  &:hover {
    @include btn_transition(0.5s);
    z-index: 2;
    position: relative;
    box-shadow: 0 0 8px rgba(0, 121, 193, 0.7);
  }

  &:hover {
    @include btn_transition(0.5s);
    box-shadow: 0 0 8px rgba(0, 121, 193, 0.7);
  }
}

.rounded_btn__green {
  @extend .btn;
  @extend .bordered_btn;
  width: fit-content !important;
  max-width: fit-content !important;
  border: 0.5px solid $green !important;
  background-color: $cardBG !important;
  color: $white;
  @include btn_transition(0.5s);
  box-shadow: 0 0 0 rgba(49, 203, 129, 0.7);

  &:hover {
    @include btn_transition(0.5s);
    z-index: 2;
    position: relative;
    box-shadow: 0 0 8px rgba(49, 203, 129, 0.7);
  }
}

.rounded_btn__yellow {
  @extend .rounded_btn__green;
  border: 0.5px solid $yellowMarker !important;
  box-shadow: 0 0 0 rgba(255, 202, 65, 0.7);

  &:hover {
    box-shadow: 0 0 8px rgba(255, 202, 65, 0.7);
  }
}

.rounded_btn__red {
  @extend .btn;
  @extend .bordered_btn;
  border: 0.5px solid $red !important;
  background-color: transparent !important;
  color: $white;
  @include btn_transition(0.5s);
  box-shadow: 0 0 0 rgba(205, 65, 65, 0.7);

  &:hover {
    @include btn_transition(0.5s);
    z-index: 2;
    position: relative;
    box-shadow: 0 0 8px rgba(205, 65, 65, 0.7);
  }
}

.button_container {
  display: flex;
  justify-content: flex-end;
  border-top: 0.5px solid $divider;
  padding-top: 20px;
  margin-right: 27px;
}

.button_container__content_right {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.button_container__voting {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 10px;
}

.button_container__top_divider {
  padding-top: 15px;
  border-top: 0.5px solid $divider;
}

.large_btn__title {
  white-space: nowrap;
  color: $lightGray;
  line-height: 30px;
  user-select: none;
}

.large_btn__title__center {
  white-space: nowrap;
  color: $lightGray;
  line-height: 30px;
  user-select: none;
  justify-content: center;
  display: flex;
  padding-bottom: 10px;
}

.simple-btn {
  @extend .btn;
  bottom: 20px;
  height: 35px;
  margin: 0 22px;
  font-size: $secondaryTextSize;

  .large_btn__title {
    color: $blue;
    transition: color 0.2s ease-out;

    &:hover {
      transition: color 0.2s ease-out;
      color: #4cb6ff;
    }
  }

  &_white {
    @extend .simple-btn;

    & .large_btn__title {
      color: white;

      &:hover {
        color: $blueLink;
      }
    }
  }

  &__red {
    @extend .simple-btn;

    & .large_btn__title {
      color: $red;

      &:hover {
        color: #f06767;
      }
    }
  }

  &_with-background {
    background-color: #2B3647;
    border-radius: 5px;
    color: #0079C1;
    user-select: none;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3.125rem;
    width: 100%;

    &:hover {
      cursor: pointer;
      color: white !important;
    }
  }
}

.bordered_btn__blue {
  @extend .bordered_btn;
}

.bordered_btn__red {
  @extend .bordered_btn;
  border-color: $lightRed;

  &:hover {
    box-shadow: 0 0 8px $semiTransparentRed;
  }

  &:active {
    box-shadow: 0 0 8px $semiTransparentRed;
  }
}

.bordered_btn__red__inactive {
  @extend .bordered_btn__red;
  cursor: not-allowed;
  opacity: 0.5;

  & div {
    cursor: not-allowed;
  }

  &:hover {
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }
}

.btn_solid__green {
  @extend .btn_solid;
  font-family: 'DIN Pro';
  background-color: $green;

  &:hover {
    box-shadow: 0 0 8px $semiTransparentGreen;
  }

  &:active {
    box-shadow: 0 0 8px $semiTransparentGreen;
  }
}

.btn_solid__light-blue {
  background-color: $lightBlueBtnBG;

  .large_btn__title {
    color: $blue;
  }

  &:active {
    background-color: $topCardBGActive;
  }
}

.btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__item {
    padding: 0.25rem 0;
    flex: 1;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: $lightBlueBtnBGHover;
    }

    &:not(:last-child) {
      margin-right: 3px;
    }
  }
}

.modal__button_wrapper {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  margin-top: 30px;
  bottom: 30px;
  right: 30px;
}

.btn__chart {
  @extend .rounded_btn__green;
  @extend .align_self__center;
  margin-right: 20px;
}

.button_container__user_booking__content_right {
  @extend .button_container__content_right;
  align-self: flex-end;
  margin-top: 15px;
}

.on_air_switch__block {
  position: absolute;
  top: 19px;
  right: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  //height: inherit;
  min-width: 1px;
  overflow: visible;
}

.on_air_switch__btn {
  background-color: $onAirBtnWhiteBG;
  height: 26px;
  width: 34px;
  border-radius: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
}

.on_air_switch__btn__active {
  @extend .on_air_switch__btn;
  transition: background-color 0.3s ease-out;
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  animation-name: pulse;
}

.on_air_switch__btn__focused {
  @extend .on_air_switch__btn;
  transition: background-color 0.3s ease-out;
  background-color: rgba(255, 255, 255, 0.4);
}

@-webkit-keyframes pulse {
  0% {
    background: #623434;
  }
  40% {
    background: #824a4a;
  }
  100% {
    background: #623434;
  }
}

@keyframes pulse {
  0% {
    background: #623434;
  }
  40% {
    background: #824a4a;
  }
  100% {
    background: #623434;
  }
}

.on_air_switch__btn_circle {
  background-color: $onAirBtnWhite;
  border-radius: 5px;
  cursor: pointer;
  height: 6px;
  width: 6px;
  transition: background-color 0.2s ease-out;
}

.on_air_switch__btn_circle__active {
  @extend .on_air_switch__btn_circle;
  background-color: $onAirBtnRed;
  transition: background-color 0.3s ease-out;
}

.on_air_switch__btn_circle__focused {
  @extend .on_air_switch__btn_circle;
}

.on_air_switch__title {
  display: inline-block;
  width: 69px;
  opacity: 0;
  font-family: 'DIN Pro';
  margin-top: -50px;
  font-size: $mainTextSize;
  color: $onAirBtnRed;
  text-transform: uppercase;
  margin-right: 10px;
  user-select: none;
  overflow: hidden;
  transition: margin-top 0.2s ease-out, opacity 0.2s ease;
}

.on_air_switch__title__active {
  @extend .on_air_switch__title;
  margin-top: 0;
  opacity: 1;
  transition: margin-top 0.3s ease-out, opacity 0.2s ease;
}

.on_air_switch__title__focused {
  @extend .on_air_switch__title;
}

.chart_type_switch__block {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 34px;
  max-width: 34px;
  margin-left: 10px;
}

.chart_type_switch__button {
  width: 34px;
  height: 34px;
  border-radius: 6px;
  background-color: $lightBtnBG;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 6px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  transition: border 0.1s ease, background-color 0.1s ease, box-shadow 0.2s ease;
}

.chart_type_switch__button__active {
  @extend .chart_type_switch__button;
  background-color: $cardBG;
  border: 1px solid $green;
  box-shadow: 0 0 5px rgba(49, 203, 129, 0.7);
}

.btn_container__vote_form {
  @extend .button_container__top_divider;
  @extend .button_container__content_right;
  align-self: flex-end;
  flex: none;
  margin: 10px 17px 0 0;
  width: calc(100% - 20px);
  //max-width: calc(100% - 20px);
  padding-top: 0;
  max-height: unset;

  & .btn_solid {
    margin-top: 17px;
  }
}

.btn__add_notification {
  @extend .icon__add_rounded__green !optional;
  position: absolute;
  top: 24px;
  right: 24px;
}

.btn__send {
  width: 28px;
  height: 28px;
  min-width: 28px;
  min-height: 28px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px 0 14px;
  transition: background-color 0.4s ease, border 0.4s ease, opacity 0.4s ease;
}

.btn__send__inactive {
  @extend .btn__send;
  opacity: 0.6;
  background-color: transparent;
  border: 1px solid $white;
}

.btn__send__active {
  @extend .btn__send;
  opacity: 1;
  background-color: $blue;
  border: 1px solid transparent;
}

.btn__add_zone {
  @extend .card_list__item__small;

  & .card_list__item__zone_name {
    text-align: center;
    color: $blue;
  }
}

.btn__add_floor {
  @extend .card_list__item__small;

  & .card_list__item__floor_name {
    text-align: center;
    color: $blue;
  }
}

.auth__btn {
  @extend .button_container__content_right;
  max-height: fit-content;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 20px;

  & .btn_solid {
    margin: 0;
    width: fit-content;
  }
}

.input_suffix_btn {
  background-color: $topCardBG;
  border-width: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 0 4px 4px 0;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.input_suffix_btn__disabled {
  @extend .input_suffix_btn;
  cursor: not-allowed;
  color: $lightBtnBG !important;
}

.input_suffix_btn__inactive {
  @extend .input_suffix_btn;
  color: $mainText;

  &:hover {
    color: $white;
    background-color: $blue;
  }
}

.input_suffix_btn__active {
  @extend .input_suffix_btn;
  background-color: $blue;
  color: $white;
}

.map__button {
  font-family: 'DIN Pro Light';
  color: $lightGray;
  background-color: $cardListDivider;
  border: 0;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  font-size: 20px;
  padding: 2px 2px 4px 2px;
  line-height: 1.1;
  cursor: pointer;
  margin-bottom: 6px;
  transition: color 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease;
  outline: none !important;
  user-select: none;

  &:active {
    color: $blueLink;
    background-color: #242d3a;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  }

  &:hover {
    color: $blueLink;
  }
}

.type_btn {
  border-radius: 2px;
  background-color: $cardListDivider;
  color: white;
  padding: 6px;
  text-align: center;

  &:first-child {
    margin-bottom: 4px;
  }
}

.button__edit_office_screen {
  @extend .rounded_btn__yellow;
  @extend .margin_right__17;
  @extend .overflow_visible;
}

.button__edit_zones {
  @extend .rounded_btn__green;
  @extend .margin_right__17;
  @extend .overflow_visible;
}

.button__new_user_in_group {
  @extend .rounded_btn__green;
  @extend .margin_right__17;
  @extend .overflow_visible;
}

.button__import_users_csv {
  @extend .rounded_btn__yellow;
  @extend .margin_right__17;
  @extend .overflow_visible;
}

.btn__user_card__top_right {
  @extend .simple-btn;
  color: $secondaryText;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  transition: color 0.2s ease;

  & div:hover {
    color: $blue;
  }
}

.btn-add-image {
  @extend %hover_blue;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid $blue;
  position: relative;
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  background-color: $topCardBG;

  &__icon_blue {
    display: block;
    margin: auto;
    height: min-content;

    svg {
      display: block;
      height: 100%;

      path {
        fill: $blue;
      }
    }
  }
}

.btn-remove-image {
  @extend %hover_red;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid $red;
  position: relative;
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  background-color: $topCardBG;
  margin-left: 10px;

  &__icon_red {
    display: block;
    margin: auto;
    height: min-content;

    svg {
      display: block;
      height: 100%;

      path {
        fill: $red;
      }
    }
  }
}

.ant-btn-primary,
.ant-btn-primary:active,
.ant-btn-primary:focus {
  background-color: $blue;
  color: $white;
  font-size: 14px;
  border-radius: 30px;
  border-color: $blue;
  padding: $btnPaddingY $btnPaddingX;

  &:hover {
    background-color: $blue;

    &:not(:disabled) {
      @include btn_transition(0.5s);
      box-shadow: 0 0 8px rgba(0, 121, 193, 0.7);
      border-color: $blue;
      color: $white;
    }
  }

  &:disabled,
  &:disabled:hover {
    opacity: 0.5;
    background-color: $blue;
    border-color: $blue;
    color: $white;
  }
}

.ant-btn-text,
.ant-btn-text:active,
.ant-btn-text:focus {
  background: none;
  color: white;
  border: none;
  padding: $btnPaddingY $btnPaddingX;

  &:hover {
    color: $blue;
    background: none;
  }
}

.ant-btn-danger {
  border-radius: 30px;
  border-color: $red;
  color: $red;
}
