.room-map-screen__menu-wrap {
  flex-grow: 1;
  padding-right: calc(5rem/16);
}

.container__room__map_screen .ant-menu-inline {
  background-color: transparent !important;
  border-right: none !important;
  box-shadow: none !important;
  user-select: none !important;
  flex-grow: 1;
  // overflow-x: scroll !important;
}

.container__room__map_screen .anticon-caret-down {
  color: #FFF !important;
}

.container__room__map_screen .ant-menu-submenu-title {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: 1px solid transparent;
  margin: 0 !important;
  & .ant-menu-submenu-arrow {
    display: none;
  }
}

// room-wrap styles
.ant-menu-submenu-inline ul li  {
  background-color: #202630;
  min-height: calc(76rem/16);
  padding-top: 0 !important;
  padding-left: 0;
  margin-bottom: calc(10rem/16);
  border-radius: calc(5rem/16) !important;
  border: 1px solid transparent;
  &:hover .room-item__room-zone {
    color:#7A7D82 !important;
  }
}

.ant-menu-submenu-inline ul li.ant-menu-submenu-open .ant-menu-submenu-title  {
  border: 1px solid #0079C1;
  &:hover {
    background-color: #202630 !important;
  }
}

.ant-menu-submenu-inline ul li .ant-menu-submenu-title {
  border: 1px solid transparent;
  border-radius: calc(5rem/16);
  &:hover {
    cursor: pointer;
    border: 1px solid #0079C1
  }
}

.ant-menu-submenu-inline ul li .ant-menu-submenu-title {
  min-height: calc(76rem/16);
}

.rooms-list__info-and-icon-wrap {
  display: flex;
  width: 75%;
  max-width: 75%;
  padding-right: calc(5rem/16);
  align-items: center;
  @media screen and (max-width: 1140px){
    max-width: 60%;
  }
}

.room-type-group-title {
  color: #FBFBFB;
  font-family: 'DIN Pro bold';
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
  line-height: calc(20.61rem/16);
}

.room-type-group-title {
  margin-right: calc(8rem/16);
}

.rooms-list__room-title-wrap {
  height: calc(76rem/16);
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: calc(20rem/16);
  padding-right: calc(5rem/16);
}

.room-item__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  padding-right: calc(5rem/16);
  overflow: hidden;
  max-height: 100%;
  line-height: calc(25rem/16) !important;
}

.room-item__room-title {
  font-size: calc(16rem/16);
  max-width: 100%;
  color:#fff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; 
}

.room-item__room-type {
  max-width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; 
  color:#7A7D82;
}

.room-item__room-zone {
  color:#7A7D82;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; 
  max-width: 100%;
}


.room-item__add-icon-wrap {
  border-radius: 50%;
  text-align: center;
  width: calc(35rem/16);
  height: calc(35rem/16);
  display: flex;
  align-items: center;
  justify-content: center;
}

// table-wrap styles
.ant-menu-submenu-inline ul li ul li{
  background-color: transparent !important;
  min-height: calc(76rem/16);
  padding: 0 !important;
}

.ant-menu-submenu-inline ul li ul li .room-list-table-wrap{
  padding-left: calc(20rem/16) !important;
  height: 100%;
  border: 1px dashed rgba(255, 255, 255, 0.15) !important;
  overflow: hidden !important;
  background-color: transparent !important;
  display: flex;
  transition: all 0.3s;
  align-items: center;
  &:hover {
    border: 1px dashed rgba(255, 255, 255, 0.35) !important;
  }
}

.room-list-table-wrap .icon__more {
  margin-left: calc(5rem/16);
}

.table-item__info-wrap {
  flex-grow: 1;
  max-width: 75%;
  align-self: stretch;
  padding-top: calc(10rem/16);
  @media screen and (max-width: 1140px){
    max-width: 60%;
  }
}

.table-item__title {
  font-size: calc(13rem/16);
  font-weight: 400;
  letter-spacing: 0.02em;
  display: block;
  max-width: 100%;
  color: #FFF;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; 
  padding-right: calc(5rem/16);
  line-height: calc(20rem/16) !important;
}

.room-item__another-info {
  width: 100%;
  display: flex;
  align-items: center;
}

.square {
  width: calc(5rem/16);
  height: calc(5rem/16);
  background-color: #7A7D82;
  border-radius: 50%;
  margin-right: calc(8rem/16);
  margin-left: calc(8rem/16);
  transform: translateY(25%);
}

.table-item__marker-icons-wrap {
  display: flex;
  align-items: center;
  &.grab {
    cursor: grab !important;
  }
}

.table-item__marker-icon-wrap {
  margin-bottom: calc(1rem/16); 
  align-self: stretch; 
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(36rem/16); 
  width: calc(36rem/16);
}

.table-item__menu-icon-wrap {
  width: calc(35rem/16);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: calc(5rem/16);
}


.table-item__tag {
  background-color: #7A7D82;
  border-radius: calc(20rem/16);
  font-size: calc(10rem/16);
  font-weight: 700;
  padding: calc(4rem/16) calc(8rem/16);
  margin-right: calc(4rem/16);
  color: #FFF
}

.room-item__spin-wrap {
  padding: calc(15rem/16);
}