@import '../../styles//constants.scss';

.marker__toolbar {
  position: absolute;
  background-color: $lightGray;
  width: 20%;
  min-height: 3rem;
  box-sizing: border-box;
  color: black;
  border-radius: 40px;
  padding: 0.25rem 0.45rem;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  pointer-events: auto;
  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: 100%;
    left: calc(50% - 6px);
    border-style: solid;
    border-width: 4px 5px 0 5px;
    border-color: $lightGray transparent transparent transparent;
  }
  .tooltip {
    width: 35px;
    height: 35px;
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    background-color: transparent;
    transition: all .2s ease-in-out;
    display: flex;
    svg {
      margin: auto;
      z-index: 1;
    }
    &:hover {
      background-color: $lightBlue;
    }
    &:active {
      background-color: #cddceb;
    }
  }

  .tooltip::after {
    background: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
    color: #FFF;
    content: attr(data-tooltip);
    margin-top: -45px;
    opacity: 0;
    padding: 6px 8px;
    position: absolute;
    visibility: hidden;
    width: max-content;
        
    transform: translateX(calc(-50% + 17px));
    transition: all 0.4s ease-in-out;
  }
      
  .tooltip:hover::after {
    opacity: 1;
    visibility: visible;
  }

  &.wide {
    height: 240px;
    border-radius: 8px;
    margin-top: -190px;
    z-index: 4;
    padding: 0;
    flex-direction: column;
    .info {
      display: flex;
      width: 100%;
      flex: 1;
      flex-direction: column;
    }
    span {
      display: block;
    }
    .title {
      width: 100%;
      font-size: $mainTextSize;
    }
    .subtitle {
      margin-top: 4px;
      font-family: 'DIN Pro';
      font-size: $smallTextSize;
      letter-spacing: 0.2px;
    }
    .info-wrapper {
      padding: 8px;
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      cursor: initial;
      z-index: 2;
      &:hover {
        background-color: initial !important;
      }
    }
    .button-wrapper {
      width: 100% !important;
      display: flex;
      flex-direction: row;flex-wrap: nowrap;
      height: 40px;
      border-top: 1px solid #C4C4C4;
      .button {
        flex: 1;
        cursor: pointer;
        display: flex;
        span {
          margin: auto;
        }
        &:first-child {
          border-right: 1px solid #C4C4C4;
          border-radius: 0 0 0 8px;
        }
        &:last-child {
          border-right: 1px solid #C4C4C4;
          border-radius: 0 0 8px 0;
        }
        transition: background-color .2s ease;
        &:hover {
          background-color: $lightBlue;
        }
      }
      &:hover {
        background-color: initial !important;
      }
    }
    .image {
      border-radius: 8px 8px 0 0;
      flex: 3;
      width: 100%;
      background-color: #9C9C9C;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      transition: background-size .4s ease;
      cursor: initial;
      & > div:hover {
        background-color: #9C9C9C;
      }
      &:hover {
        background-size: 120%;
      }
    }
    &::after {
      display: none;
    }
  }
}

.marker-click__closeicon-wrap {
  border-radius: 50%;
  padding: calc(5rem/16);
  background-color: transparent;
  transition: all .2s ease-in-out;
  &:hover {
    background-color: #E1ECF7;
  }
}