.ant-popover-inner-content {
  padding: calc(20rem/16);
}

.menu-content-wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
  &:hover {
    cursor: pointer;
  }
}

.default-functioun-list-wrap {
  display: flex;
  flex-direction: column;
}

.default-functioun-wrap {
  display: flex;
  align-items: center;
  margin-bottom: calc(10rem/16);
  &:hover .default-function{
    color: #1890ff !important;
  }
}


.map-screen__menu-edit-icon {
  width: calc(12rem/16);
  height: calc(12rem/16);
  background: url('../../../assets/images/icons/editRoom.svg');
  margin-right: calc(10rem/16);
}

.map-screen__menu-map-icon {
  width: calc(12rem/16);
  height: calc(12rem/16);
  background: url('../../../assets/images/icons/map_screen_menu_map.svg');
  margin-right: calc(10rem/16);
}

.default-function {
  transition: all 0.3s;
  color: #7A7D82;
  font-size: calc(12rem/16);
}

.dangerous-functioun-wrap {
  padding-top: calc(10rem/16);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  align-items: center;
  &:hover .dangerous-function{
    color: #f06767 !important;
  }
}

.dangerous-function {
  transition: all 0.3s;
  color: #CD4141;
  font-size: calc(12rem/16);
}

.map-screen__menu-bucket-icon {
  width: calc(10rem/16);
  height: calc(11rem/16);
  background: url('../../../assets/images/icons/map_screen_bucket.svg');
  margin-right: calc(10rem/16);
}